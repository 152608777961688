<template>
  <v-container id="data-tables-view" fluid tag="section">
    <material-card
      icon="mdi-clipboard-list"
      icon-small
      color="primary"
      title="Console logs"
    >
      <v-card-text>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          class="ml-auto"
          hide-details
          label="Search records"
          single-line
          style="max-width: 250px"
        />

        <v-divider class="mt-3" />

        <v-data-table
          :headers="headers"
          :items="logs"
          :search.sync="search"
          :footer-props="{
            showFirstLastPage: true,
            'items-per-page-options': [5, 10, 15],
          }"
        >
        </v-data-table>
      </v-card-text>
    </material-card>
  </v-container>
</template>
<script>
import moment from "moment";

export default {
  name: "DeviceLogs",
  data: () => ({
    permissions: localStorage.getItem("permissions"),
    dialogDelete: false,
    options: {},
    total: 0,
    loading: false,
    headers: [
      {
        text: "Log",
        value: "log",
      },
    ],
    logs: [],
    awaitingSearch: null,
    search: "",
  }),

  computed: {},

  created() {
    this.$ordersHub.$on("console-log", this.addLog);
  },
  beforeDestroy() {
    this.$ordersHub.$off("console-log", this.addLog);
  },

  methods: {
    addLog(log) {
      this.logs.unshift({ log });
    },
  },
};
</script>
